import React from "react";
import { BodyText, Heading, Title } from "@src/components/new-nessie";
import { Link } from "gatsby";

type DocumentList = {
  title: string;
  documents: {
    slug: string;
    publicationDate: string;
  }[];
}[];

type LegalHistoryProps = {
  pageContext: {
    docList: {
      childrens: DocumentList;
      tos: DocumentList;
      main: DocumentList;
      website: DocumentList;
      "premium-tos": DocumentList;
    };
    localeStrings: any;
  };
};

const LegalHistoryLayout: React.FC<LegalHistoryProps> = ({ pageContext }) => {
  return (
    <>
      <Title>Legal documents history</Title>
      {Object.keys(pageContext.docList).map((key, index) => (
        <div key={index}>
          <Heading>{pageContext.docList[key].title}</Heading>
          <ul>
            {pageContext.docList[key].documents.map((document, index) => (
              <BodyText as="li" key={index}>
                <Link to={`/legal-history/${document.slug}`}>
                  {new Date(document.publicationDate).toLocaleDateString()}
                </Link>
              </BodyText>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
};

export default LegalHistoryLayout;
